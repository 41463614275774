import {
  onMounted, onUnmounted, computed, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head';
// import { useGtm } from '@gtm-support/vue-gtm';

const getUri = () => `${window.location.pathname}${window.location.search}${window.location.hash}`;

export default function () {
  const store = useStore();
  // const gtm = useGtm();

  function popstate(history) {
    store.dispatch('router/pop', { path: getUri(), data: history.state });
  }

  const meta = computed(() => store.state.router.popup?.meta || store.state.router.page?.meta);
  useHead({
    htmlAttrs: { lang: computed(() => store.state.router.locale) },
    title: computed(() => meta.value?.title),
    meta: computed(() => [
      { name: 'description', content: meta.value?.description },
      { property: 'og:description', content: meta.value?.description },
      { property: 'og:title', content: meta.value?.title },
      { property: 'og:type', content: 'website' },
    ]),
    link: computed(() => {
      if (!meta.value) return [];
      const tags = [];
      Object.entries(meta.value.translatedPaths)
        .forEach((e) => {
          tags.push({ rel: 'alternate', hreflang: e[0], href: e[1] });
        });
      return tags;
    }),
  });

  watch(() => store.state.router.page, () => {
    // GTM tracking
    // if (gtm) gtm.trackView(meta.value?.title, window.location.pathname);
  });

  onMounted(async () => {
    try {
      await store.dispatch('router/init', { uri: getUri(), hydrate: !!window.INITAL_ROUTER });
    } catch (err) {
      console.error('Server unreachable', err);
    }

    window.addEventListener('popstate', popstate);

    if (window.prerender) {
      // wait end of animations
      setTimeout(() => {
        // store state
        const script = document.createElement('script');
        script.innerHTML = `window.INITAL_ROUTER = ${JSON.stringify(store.state.router)};`;
        document.head.appendChild(script);

        // we're done
        window.document.dispatchEvent(new Event('prerender-trigger'));
      }, 5000);
    }
  });

  onUnmounted(() => {
    window.removeEventListener('popstate', popstate);
  });
}
