<template>
  <div v-if="translatedPaths" class="language-switch text-sitem flex flex-col gap-2 items-center" :class="{large}">
    <Globe
      class="globe cursor-pointer hover:opacity-80"
      :style="{width: large ? '2.5rem' :'0.9rem', height: large ? '2.5rem' :'0.9rem'}"
    />
    <div class="list-wrapper flex flex-col">
      <Go
        v-for="(locale, index) in locales"
        :key="`locale-${locale}`"
        :to="translatedPaths[locale] || ''"
        class="language-link"
        :style="{'--index': index}"
      >
        {{ locale }}
      </Go>
    </div>
  </div>
</template>

<script setup>
import Globe from '@images/icons/globe.svg';
import Go from '@/components/utils/Go.vue';
import { computed, defineProps } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({ large: Boolean });

const store = useStore();

const locales = computed(() => store.state.router?.locales);
const translatedPaths = computed(() => store.state.router?.page?.meta?.translatedPaths);

</script>

<style scoped lang="scss">

.globe {
  position: relative;
  z-index: 2;
}
.language-switch {
  position: relative;
  padding-top: 4px;

  .list-wrapper {
    background-color: white;
    border-radius: 5px;
    opacity: 0;
    position: absolute;
    top: 0;
    padding-top: 1.25rem;
    padding-bottom: 0.25rem;
    transform: translateY(-100%);
    pointer-events: none;
    transition: opacity 350ms, transform 350ms;
    transition-timing-function: ease-in-out;
    transition-delay: 100ms;
    z-index: 1;

    .language-link {
      padding: 5px;
      cursor: pointer;
      transition: all 350ms;
      transition-timing-function: ease-in-out;
      transition-delay: 100ms;
      line-height: 1;
      text-align: center;

      &:hover {
        opacity: 0.8 !important;
      }
    }
  }
  &.large {
    .list-wrapper {
      padding-top: 3rem;
      .language-link {
        padding: 5px 15px;
        line-height: 2.5;
        font-size: larger;
      }
    }
  }
}

div.language-switch {
  &:hover {
    background-color: white;
    .list-wrapper {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
      box-shadow: 0 0 1px 1px rgba(0,0,0,0.1);
    }

    svg {
      opacity: 0.8;
    }
  }
}

</style>
