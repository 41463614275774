<template>
  <span v-if="!src" />
  <span v-else-if="src.svg" :alt="src.alt" v-html="src.svg" />
  <img
    v-else
    ref="image"
    :alt="src.alt"
    :loading="!directLoad && 'lazy'"
  >
</template>

<script>

export default {
  props: {
    src: [Object, String],
    directLoad: Boolean,
  },
  mounted() {
    if (!this.src || this.src.svg) return;

    this.observer = new ResizeObserver(this.updateSrc);
    this.observer.observe(this.$refs.image);
  },
  beforeUnmount() {
    if (this.observer && this.$refs.image) this.observer.unobserve(this.$refs.image);
  },
  watch: {
    src() {
      this.updateSrc();
    },
  },
  methods: {
    updateSrc() {
      if (typeof this.src === 'string') {
        this.$refs.image.src = this.src;
        return;
      }

      const boxWidth = this.$refs.image.getBoundingClientRect().width * window.devicePixelRatio;

      if (boxWidth <= 600) this.$refs.image.src = this.src.small;
      else if (boxWidth <= 1000) this.$refs.image.src = this.src.normal;
      else this.$refs.image.src = this.src.large;
    },
  },

};
</script>
