<template>
  <footer class="Footer part relative bg-sitem text-white">
    <div class="footerInner relative">
      <div class="row row-12 mb-6 md:mb-0 w-fit">
        <Go :to="'/' + $store.state.router.locale" aria-label="Home">
          <Logo class="logo" />
        </Go>
      </div>
      <div class="row row-12 hidden md:block ">
        <form class="flex flex-row items-center mt-12 mb-12 sm:mb-16 sm:mt-8">
          <!--          <label for="search" />-->
          <!--          <input-->
          <!--            id="search"-->
          <!--            class="inline-block"-->
          <!--            type="text"-->
          <!--            name="search"-->
          <!--            :placeholder="payload.search"-->
          <!--          >-->
          <!--          <button>-->
          <!--            <Lupe class="lupe" />-->
          <!--          </button>-->
        </form>
      </div>
      <div class="row row-12">
        <div class="col col-12 sm:col-6 md:col-3 hidden md:block">
          <div v-html="payload.adress" />
        </div>
        <div class="col col-12 sm:col-6 md:col-3 firstMenue lg:flex md:flex-col pl-0 lg:pl-0">
          <Go
            v-for="link in payload.firstMenue"
            :key="link"
            class="h4 uppercase mb-3"
            :to="link.link"
            v-html="link.name"
          />
        </div>
        <div class="col col-12 md:col-3 secondMenue lg:flex md:flex-col">
          <Go
            v-for="link in payload.secondMenue"
            :key="link"
            :to="link.link"
            class="mb-3"
            v-html="link.name"
          />
        </div>
        <div class="col col-12 md:col-3 flex lg:justify-end pl-0 lg:pl-0 pr-0 lg:pr-8">
          <div>
            <h4 class="mb-5" v-html="payload.socialMediaTitle" />
            <div class="socials">
              <Go v-if="payload.linkedin" target="_blank" :to="payload.linkedin">
                <Linkedin class="linkedin" />
              </Go>
              <Go v-if="payload.youtube" target="_blank" :to="payload.youtube">
                <Youtube class="youtube" />
              </Go>
              <Go v-if="payload.instagram" target="_blank" :to="payload.instagram">
                <Instagram class="instagram" />
              </Go>
              <Go v-if="payload.facebook" target="_blank" :to="payload.facebook">
                <Facebook class="facebook" />
              </Go>
              <Go v-if="payload.xing" target="_blank" :to="payload.xing">
                <Xing class="xing" />
              </Go>
            </div>
          </div>
        </div>
        <div class="col col-12 md:hidden">
          <div class="mt-4 lg:mt-0" v-html="payload.adress" />
        </div>
      </div>
      <div class="row row-12">
        <div class="mt-12" v-html="payload.claimer" />
      </div>
    </div>
    <div class="image aspect">
      <Image :key="payload.background" :src="payload.background" />
    </div>
  </footer>
</template>

<script>
import Facebook from '@/assets/images/icons/socials/facebook.svg';
import Youtube from '@/assets/images/icons/socials/youtube.svg';
import Xing from '@/assets/images/icons/socials/xing.svg';
import Instagram from '@/assets/images/icons/socials/instagram.svg';
import Linkedin from '@/assets/images/icons/socials/linkedin.svg';
import Logo from '@/assets/images/logo-footer.svg';
import Lupe from '@/assets/images/icons/lupe.svg';

export default {
  components: {
    Facebook,
    Youtube,
    Xing,
    Instagram,
    Linkedin,
    Logo,
    Lupe,
  },
  computed: {
    payload() {
      return this.$store.state.router.layout.footer || {};
    },
  },
};
</script>

<style scoped lang="scss">
footer {
  color: #FFF;
  padding: px(30) px(30) px(20) px(30);
  @screen sm {
    padding: px(40) px(40) px(20) px(40);
  }
  @screen md {
    padding: px(60) px(60) px(20) px(60);
  }
  @screen lg {
    padding: px(80) px(80) px(20) px(80);
  }
  .footerInner {
    z-index: 2;
  }

  :deep(p a:hover), a:hover {
    color: #FFF;
    text-decoration: underline;
  }

  svg {
  }

  .logo {
    width: px(276);
    height: auto;
  }

  .lupe {
    width: px(23);
    height: auto;
  }

  div p:last-of-type {
    margin-bottom: 0px;
  }

  .col + .col {
    margin-top: px(20);
    @screen sm {
      margin-top: px(25);
    }
    @screen md {
      margin-top: px(30);
    }
    @screen lg {
      margin-top: px(0);
    }
  }

  input {
    height: px(30);
    width: 100%;
    max-width: px(210);
    padding: px(3) px(3) px(10) px(3);
    background-color: transparent;
    border: 0 solid transparent;
    margin-right: px(10);
    border-bottom: px(2) solid #FFF;

    &::placeholder {
      color: #FFF;
    }
  }

  .firstMenue, .secondMenue {
    a {
      display: inline-block;
      margin-right: px(20);
      @screen md {
        margin-right: px(0);
        display: inherit;
      }

      &:last-of-type {
        margin-right: px(0);
      }
    }
  }

  .image {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    height: 100%;
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
    display: none;
    @screen sm {
      display: inherit;
    }
  }

  .socials {
    svg {
      height: auto;
    }

    a + a {
      margin-left: px(20.38);
    }

    .linkedin {
      width: px(21.5);
    }

    .youtube {
      width: px(28.6);
    }

    .instagram {
      width: px(23.42);
    }

    .facebook {
      width: px(11.9);
    }

    .xing {
      width: px(24.56);
    }
  }
}

.col > a {
  width: fit-content;
}
</style>
