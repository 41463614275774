/* eslint-disable */
import NProgress from 'nprogress';

const MATCH_LOCALE = /^\/(..)($|\/)/;

// Prevent the scroll, always
window.history.scrollRestoration = 'manual';

const FLAGS = {
  NAME: 'JKROUTER',
  POPUP: 'POPUP',
  PAGE: 'PAGE',
};

const SCROLL_DELAY = 300;
const SCROLL_MAP = {}; // contains a map of state => { x, y }
const POPUP_MAP = {}; // contains a map of state => true|false
let CURRENT_STATE = '';

// setup AbortController
let controller = new AbortController();

const state = () => window.INITAL_ROUTER || ({

  base: 'https://sitem-insel.ch/api',
  beta: ['localhost', 'beta.'].find((domain) => window.location.hostname.startsWith(domain)),
  locales: ['de', 'en', 'fr'],
  locale: 'en',
  layout: {},
  pages: {
    404: {
      content: [{component: 'NotFound', data: {id: -1}}, {
        component: 'Footer',
        data: {id: -2}
      }]
    },
  },
  page: {},
  popup: null,
  openedMenu: null,
});

const abortPending = () => {
  controller.abort();
  console.log('abort existing requests')
  controller = new AbortController();
}

const createHistoryHash = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
};

const pushInternalState = (popup) => {
  const hash = createHistoryHash();
  POPUP_MAP[hash] = popup;
  CURRENT_STATE = hash;
  return hash;
}

const storeScrollPosition = (state) => {
  if (POPUP_MAP[state]) {
    SCROLL_MAP[state] = {
      x: 0,
      y: 0,
    };
  } else {
    SCROLL_MAP[state] = {
      x: window.scrollX,
      y: window.scrollY,
    };
  }
};

const actions = {

  /**
   * init router
   */
  async init({state, commit, dispatch}, {uri, hydrate = false}) {

    // find locale from url

    const urlLocale = uri.match(MATCH_LOCALE)?.[1];
    if (urlLocale && state.locales.includes(urlLocale)) {
      await commit('setLocale', urlLocale);
    }
    if (uri.length <= 3) {
      // eslint-disable-next-line no-param-reassign
      uri = `/${state.locale}`;
    }

    // load everything
    if (!hydrate) {
      await Promise.all([
        dispatch('loadLayout'),
        dispatch('push', {uri}),
      ]);

      // set state
    } else {
      window.history.replaceState({
        name: FLAGS.NAME,
        hash: pushInternalState(state.popup),
        type: state.popup ? FLAGS.POPUP : FLAGS.PAGE,
      }, undefined, uri);
    }
  },

  /**
   * load new layout
   */
  async loadLayout({state, commit}) {
    const response = await fetch(
      `${state.base}/${state.locale}/ajax/layout`,
      {headers: {Accept: 'application/json', View: state.beta ? 'PREVIEW' : ''}},
    );
    const json = await response.json();
    await commit('setLayout', json);
  },

  /**
   * load new page (shows progress bar)
   */
  async loadPage({state, commit}, {uri}) {
    NProgress.start();

    try {
      const response = await fetch(
        `${state.base}${uri}`,
        {
          headers: {Accept: 'application/json', View: state.beta ? 'PREVIEW' : ''},
          signal: controller.signal
        },
      );

      let incomingUri = response.url;
      // remove host
      incomingUri = incomingUri.replace(state.base, '');
      // remove query
      // [incomingUri] = incomingUri.split('?');

      const json = await response.json();
      const store = {uri: incomingUri, data: json};
      await commit('addPage', store);
      NProgress.done();

      return store;
    } catch (e) {
      NProgress.done();
      // we want the callee to handle the exception as well
      throw e;
    }
  },

  /**
   * set new popup state
   */
  async popup({state, commit, dispatch}, {uri, action = 'pushState', onRecord = true}) {
    abortPending();

    if (onRecord) {
      storeScrollPosition(CURRENT_STATE);

      window.history[action]({
        name: FLAGS.NAME,
        type: FLAGS.POPUP,
        hash: pushInternalState(true),
      }, undefined, uri);
    }

    const specialURI = `${uri}?type=popup`;

    try {
      if (!state.pages[specialURI]) await dispatch('loadPage', {uri: specialURI});

      if (state.pages[specialURI].meta.status !== 200) {
        await commit('setCurrent', 404);
      }

      await commit('setPopup', {uri: specialURI});
    } catch (e) {
      console.error('popup aborted', uri, e.message)
    }

  },

  /**
   * jump to new state (with history update)
   */
  async push({state, commit, dispatch}, {uri, noscroll, action = 'pushState', onRecord = true}) {
    abortPending();

    if (onRecord) {
      storeScrollPosition(CURRENT_STATE);

      window.history[action]({
        name: FLAGS.NAME,
        type: FLAGS.PAGE,
        hash: pushInternalState(false),
        noscroll
      }, undefined, uri);
    }

    let specialURI = uri;
    try {
      if (!state.pages[specialURI]) {
        specialURI = (await dispatch('loadPage', {uri: specialURI})).uri
      }

      // if locale changes
      const urlLocale = specialURI.match(MATCH_LOCALE)?.[1];
      if (urlLocale && state.locales.includes(urlLocale) && urlLocale !== state.locale) {
        commit('setLocale', urlLocale);
        dispatch('loadLayout');
      }

      // if state.pages[specialURI] is null, we have been redirected
      if (state.pages[specialURI] && state.pages[specialURI].meta.status !== 200) {
        await commit('setCurrent', 404);
      } else {
        await commit('setPopup', {uri: null});
        await commit('setCurrent', specialURI);
      }

      setTimeout(() => {
        if (!noscroll) window.scroll({top: 0, left: 0, behavior: 'instant'});
      }, SCROLL_DELAY);
    } catch (e) {
      console.error('push aborted', uri, e.message)
    }

  },

  /**
   * jump to previous state (with history update)
   */
  async pop({dispatch, commit, state}, {path, data}) {
    abortPending();

    // store scroll position, if it is a popup, the method handles it (stores 0)
    storeScrollPosition(CURRENT_STATE);

    // if we have no hash in the state we go to, we can't do anything
    if (!data.hash) return;

    let inner = async () => {
      if (data?.name !== FLAGS.NAME) return;

      try {
        //if (data && data.type === FLAGS.PAGE && state.popup) await commit('setPopup', { uri: null });
        if (data && data.type === FLAGS.POPUP) await dispatch('popup', {
          uri: decodeURI(path),
          onRecord: false
        });
        else await dispatch('push', {uri: decodeURI(path), onRecord: false, noscroll: state.popup});

        let oldState = CURRENT_STATE;
        CURRENT_STATE = data.hash;
        if (CURRENT_STATE in SCROLL_MAP && !data.noscroll && !POPUP_MAP[oldState]) {
          const scroll = SCROLL_MAP[CURRENT_STATE];
          setTimeout(() => {
            window.scroll({top: scroll.y, left: scroll.x, behavior: 'instant'});
          }, SCROLL_DELAY);
        }
      } catch (e) {
        console.error('pop aborted', path, e.message)
      }
    }

    if (window.pageYOffset < 300 || !POPUP_MAP[CURRENT_STATE] || !state.popup) {
      inner();
    } else {
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
      setTimeout(() => {
        inner();
      }, 600);
    }

  },

};

const mutations = {
  setOpenedMenu(state, link) {
    state.openedMenu = link;
  },

  setLayout(state, layout) {
    this.commit('router/setLocale', layout.locale);
    state.layout = layout;
  },

  addPage(state, {uri, data}) {
    state.pages[uri] = data;
  },

  setCurrent(state, uri) {
    state.page = state.pages[uri];
  },

  setPopup(state, {uri}) {
    state.popup = uri === null ? null : state.pages[`${uri}?type=popup`] || state.pages[uri];
  },

  setLocale(state, locale) {
    state.locale = locale;

    state.locales = state.locales.filter((l) => l !== locale);
    state.locales.unshift(locale);
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
