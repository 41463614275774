<template>
  <div id="overlay" />
  <Navigation />
  <main v-if="content" :class="[ `layout-col-${Math.min(2, content.second?.length + 1)}` ]">
    <!-- one column -->
    <template v-if="content.second?.length === 0">
      <Dynamic
        :inject="content.main"
        :breadcrumb="breadcrumb"
        :news-hero="content['news_hero'] || null"
      />
    </template>
    <!-- two column -->
    <template v-if="content.second?.length > 0">
      <Breadcrumb
        v-if="!breadcrumb.hidden"
        :payload="breadcrumb"
      />
      <div
        class="layout-2 row-12"
        :class="{
          'flex-row-reverse': sidebarPosition === 'l',
          'flex-row': sidebarPosition === 'r',
        }"
      >
        <div class="col-12 lg:col-8">
          <Dynamic
            :inject="content.main"
            :breadcrumb="null"
            :news-hero="content['news_hero'] || null"
          />
        </div>
        <div class="col-12 lg:col-4 flex flex-col gap-8">
          <Dynamic :inject="content.second" :news-hero="null" :breadcrumb="null" />
        </div>
      </div>
    </template>
    <Footer key="footer" class="fade-item" />
  </main>
</template>

<script>

import Footer from '@/components/structure/Footer.vue';
import Navigation from '@/components/structure/Navigation.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/hooks/dynamicPage';
import Breadcrumb from '@/components/parts/Breadcrumb.vue';

export default {
  components: {
    Breadcrumb,
    Navigation,
    Dynamic,
    Footer,
  },
  setup() {
    dynamicPage();
  },
  computed: {
    content() {
      const routerContent = this.$store.state.router.page.content;
      if (routerContent) {
        if (routerContent.component === 'Datei') {
          window.location.assign(routerContent.data.file[0].link);
        }
        return routerContent.data;
      }
      return false;
    },
    sidebarPosition() {
      return this.content?.sidebar?.[0] || '';
    },
    breadcrumb() {
      const breadcrumbAppearance = this.content?.breadcrumbappearance?.[0] || 'white';
      return {
        color: breadcrumbAppearance,
        hidden: !this.content?.breadcrumb || breadcrumbAppearance === 'none',
        string: this.content?.breadcrumb,
      };
    },
  },
};
</script>

<style lang="scss">

div.layout-2{
  > * {
    >*:first-of-type{
      padding-top: 0 !important;
      margin-top: 4rem !important;
    }
  }
}
</style>
