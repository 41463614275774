<template>
  <transition-group name="fade">
    <Component
      :is="component.component"
      v-for="component of parts"
      :id="component.data.slug"
      :key="component.data.id"
      :payload="component.data"
      class="fade-item"
    >
      <div class="part constrain">
        <p class="text-watermelon">
          Unable to load component «{{ component.component }}».
        </p>
      </div>
    </Component>
  </transition-group>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {

    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),
    Footer: defineAsyncComponent(() => import('@/components/structure/Footer.vue')),

    Hero: defineAsyncComponent(() => import('@/components/parts/Hero.vue')),
    Text: defineAsyncComponent(() => import('@/components/parts/Text.vue')),
    Sidebarpreview: defineAsyncComponent(() => import('@/components/structure/SidebarPreview.vue')),
    SidebarTitle: defineAsyncComponent(() => import('@/components/structure/SidebarTitle.vue')),
    Sidebarofferlink: defineAsyncComponent(() => import('@/components/structure/SidebarOfferLink.vue')),
    Login: defineAsyncComponent(() => import('@/components/structure/Login.vue')),
    Sidebarlinks: defineAsyncComponent(() => import('@/components/structure/SidebarLinks.vue')),
    Sidebarteam: defineAsyncComponent(() => import('@/components/structure/SidebarTeam.vue')),
    Textwithimage: defineAsyncComponent(() => import('@/components/parts/TextWithImage.vue')),
    RowofBlocks: defineAsyncComponent(() => import('@/components/parts/RowOfBlocks.vue')),
    Newsslider: defineAsyncComponent(() => import('@/components/parts/NewsSlider.vue')),
    Newslist: defineAsyncComponent(() => import('@/components/parts/NewsList.vue')),
    Successstories: defineAsyncComponent(() => import('@/components/parts/SuccessStories.vue')),
    Teamlist: defineAsyncComponent(() => import('@/components/parts/TeamList.vue')),
    TeamItem: defineAsyncComponent(() => import('@/components/parts/TeamItem.vue')),
    Imagegallery: defineAsyncComponent(() => import('@/components/parts/ImageGallery.vue')),
    Contactform: defineAsyncComponent(() => import('@/components/parts/ContactForm.vue')),
    Communityteaser: defineAsyncComponent(() => import('@/components/parts/CommunityTeaser.vue')),
    Pageembed: defineAsyncComponent(() => import('@/components/parts/PageEmbed.vue')),
    Partnergrid: defineAsyncComponent(() => import('@/components/parts/PartnerGrid.vue')),
    EventGrid: defineAsyncComponent(() => import('@/components/parts/EventGrid.vue')),
    Textwithbackground: defineAsyncComponent(() => import('@/components/parts/TextWithBackground.vue')),
    SidebarCTA: defineAsyncComponent(() => import('@/components/structure/SidebarCallToAction.vue')),
    Breadcrumb: defineAsyncComponent(() => import('@/components/parts/Breadcrumb.vue')),
    NewsHero: defineAsyncComponent(() => import('@/components/parts/NewsHero.vue')),
    Image: defineAsyncComponent(() => import('@/components/parts/SingleImage.vue')),
    PartnerLogos: defineAsyncComponent(() => import('@/components/parts/PartnerLogos.vue')),
    Search: defineAsyncComponent(() => import('@/components/parts/Search.vue')),
    Datei: defineAsyncComponent(() => import('@/components/parts/Datei.vue')),
  },
  props: {
    inject: { type: Object, default: Object },
    newsHero: { type: Object, default: Object },
    breadcrumb: String,
  },
  computed: {
    parts() {
      const inject = this.inject.slice(0).filter((part) => (part ? !part.data?.hidden : false));

      // return empty array if no content
      if (!Array.isArray(inject) || inject.length <= 0) return [];

      // add news hero as part
      if (this.newsHero && this.newsHero.component) {
        inject.unshift(this.newsHero);
      }

      // add breadcrumb as part
      if (this.breadcrumb && !this.breadcrumb.hidden) {
        const breadcrumbPart = {
          component: 'Breadcrumb',
          data: this.breadcrumb,
        };
        if (inject[0].component === 'Hero') { // add breadcrumb as second part, if first part should be Hero
          inject.unshift(inject[0]);
          inject[1] = breadcrumbPart;
        } else { // add breadcrumb as first part
          inject.unshift(breadcrumbPart);
        }
      }

      return inject;
    },
  },
};
</script>
