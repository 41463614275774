module.exports = {
  purge: {
    content: [
      './public/**/*.html',
      './src/**/*.vue',
    ],
    options: {
      safelist: [
        // 'bg-red',
      ],
    },
  },

  theme: {

    colors: {
      black: { DEFAULT: 'black' },
      white: { DEFAULT: 'white' },

      lightBlue: { DEFAULT: 'var(--color-lightblue)' },

      sitem: { DEFAULT: 'var(--color-sitem)' },
      sitemLight: { DEFAULT: 'var(--color-sitem-light)' },
      sitemGray: { DEFAULT: 'var(--color-sitem-gray)' },

      school: { DEFAULT: 'var(--color-school)' },
      services: { DEFAULT: 'var(--color-services)' },
      space: { DEFAULT: 'var(--color-space)' },

      iris: { DEFAULT: 'var(--color-iris)' },
      valantine: { DEFAULT: 'var(--color-valantine)' },
      watermelon: { DEFAULT: 'var(--color-watermelon)' },

      butternut: { DEFAULT: 'var(--color-butternut)' },
      golden: { DEFAULT: 'var(--color-golden)' },
      sonama: { DEFAULT: 'var(--color-sonama)' },

      stem: { DEFAULT: 'var(--color-stem)' },
      jade: { DEFAULT: 'var(--color-jade)' },
      pool: { DEFAULT: 'var(--color-pool)' },

    },

    fontFamily: {
      sans: ['Open Sans', 'sans-serif'],
      serif: ['Roboto Slab', 'serif'],
    },

    screens: {
      xs: '400px',
      sm: '640px',
      md: '920px',
      lg: '1200px',
      xl: '1921px',
      xxl: '2400px',
    },

    extend: {
      spacing: {
        13: '3.25rem',
        18: '4.5rem',
      },
    },
  },

};
