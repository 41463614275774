<template>
  <nav v-if="navigation" class="nav flex flex-col relative" :class="{ active: minifiedMenu }">

    <div
      class="md:hidden constrain absolute z-50 top-1/2 -translate-y-1/2
      flex items-center justify-between"
    >
      <Hamburger :class="{ active: minifiedMenu }" @click="toggle()" />
      <Go
        :to="'/' + $store.state.router.locale"
        aria-label="Home"
        class="logo block"
        @click="toggle(true)"
      >
        <Logo />
      </Go>
    </div>

    <div
      class="hidden md:flex constrain nav-top-bar
       gap-3 pt-1 absolute top-0 left-0 right-0 h-auto z-30 justify-end"
    >
      <Go
        v-for="item in navigation.top_bar_links"
        :key="`top-bar-${item['link_title']}`"
        :to="item.link"
        class="text-sitem hover:opacity-80"
        @click="toggle(true)"
      >
        {{ item['link_title'] }}
      </Go>
    </div>

    <div class="constrain flex w-full items-center self-center h-full md:bg-white">
      <Go
        :to="'/' + $store.state.router.locale"
        aria-label="Home"
        class="logo-full full mr-auto hidden md:block"
        @click="toggle(true)"
      >
        <LogoFull />
      </Go>
      <transition-group name="default">
        <ul
          :key="navigation?.links[0].id"
          class="flex md:h-full md:items-center primary-list flex-col md:flex-row
          inset-0 absolute md:relative overflow-y-auto md:overflow-hidden px-4 lg:p-0
          sm:gap-1 md:gap-4 lg:gap-12 xl:gap-16"
        >
          <li
            v-for="(link, linkIdx) of navigation?.links"
            :key="link.uri"
            class="li-primary-link md:h-full md:flex items-center w-full md:w-auto py-6 md:py-0"
          >
            <Go
              :to="hasSubLinks(linkIdx) && link.uri"
              class="body mb-0 primary-link font-bold md:font-normal"
              :class="{ active: link.uri === $store.state.router.page.meta?.uri }"
              @click="toggle(true)"
              @mouseover="hoverOn(link)"
              @focusin="hoverOn(link)"
              v-text="link.title"
            />
            <div
              v-if="link.subs.length || link.highlights.length"
              :ref="`ref${link.id}`"
              class="secondary-list-container md:shadow-md md:fixed md:inset-0 md:bg-white
              p-0 md:py-10 flex md:flex-row gap-5 flex-row xl:justify-end"
              :class="{displayed: sublinks.get(link.id).shown}"
            >
              <div
                v-if="link.highlights.length"
                class="highlights-container grid grid-cols-2 gap-4 w-full xl:max-w-[80rem]"
              >
                <Highlight
                  v-for="highlight in link.highlights"
                  :key="highlight.id"
                  :content="highlight.data"
                />
              </div>
              <ul
                class="secondary-list flex flex-col md:flex-row md:gap-4 lg:gap-6 xl:gap-10
                md:justify-between md:text-center flex-wrap md:pl-2 md:pl-0"
              >
                <li
                  v-for="(subLink, subLinkIdx) of link.subs"
                  :key="subLink.uri"
                  class="li-secondary-link lg:w-56"
                >
                  <Go
                    :to="hasSubSubLinks(linkIdx, subLinkIdx) && subLink.uri"
                    class="body secondary-link font-bold inline-block mb-0"
                    :class="{ active: subLink.uri === $store.state.router.page.meta?.uri }"
                    @click="toggle(true)"
                    v-text="subLink.title"
                  />
                  <ul
                    v-if="subLink.subs.length"
                    class="tertiary-list flex flex-col pl-4 md:pl-0 md:pl-0"
                  >
                    <li
                      v-for="subSubLink of subLink.subs"
                      :key="subSubLink.uri"
                      class="li-tertiary-link md:text-sitemGray"
                    >
                      <Go
                        :to="subSubLink.uri"
                        class="body mb-0 tertiary-link"
                        :class="{ active: subSubLink.uri === $store.state.router.page.meta?.uri }"
                        @click="toggle(true)"
                        v-text="subSubLink.title"
                      />
                    </li>

                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <li
            class="flex flex-col nav-top-bar gap-3 pb-6 md:hidden"
          >
            <Go
              v-for="item in navigation.top_bar_links"
              :key="`top-bar-${item['link_title']}`"
              :to="item.link"
              class="text-sitem hover:opacity-80"
              @click="toggle(true)"
            >
              {{ item['link_title'] }}
            </Go>
          </li>
        </ul>

      </transition-group>
    </div>
  </nav>
</template>

<script>
import Scrolllock from '@/hooks/scrolllock';
import LogoFull from '@images/logo-full.svg';
import Logo from '@images/logo.svg';
import Search from '@images/icons/search.svg';
import Go from '@/components/utils/Go.vue';
import Highlight from '@/components/structure/Highlight.vue';
import LanguageSwitch from '@/components/utils/LanguageSwitch.vue';
import { useWithinBreakpoint } from '@/hooks/breakpoints';
import Hamburger from '../utils/Hamburger.vue';

export default {
  components: {
    LanguageSwitch,
    Highlight,
    Go,
    Hamburger,
    Logo,
    LogoFull,
    Search,
  },
  setup() {
    return {
      lock: Scrolllock.lock,
      unlock: Scrolllock.unlock,
      isLargerThanMd: useWithinBreakpoint('md'),
    };
  },
  data() {
    return {
      minifiedMenu: false,
      displayed: false,
      refFn: null,
      currentLink: null,
      sublinks: new Map(),
    };
  },
  computed: {
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
  },
  watch: {
    navigation() {
      this.resetMap();
    },
  },
  mounted() {
    const openedLink = this.$store.state.router.openedMenu;
    console.log('keep alive', openedLink);
    window.addEventListener('mousemove', (e) => {
      const activeId = this.activeItem();
      const active = this.sublinks.get(activeId);

      if (active && activeId === this.currentLink && active.hasSubs) {
        const bounds = active.bounds();
        if (bounds.top >= 0) {
          if (e.clientY > bounds.top + bounds.height) {
            this.currentLink = null;
            this.resetMap();
            this.$store.commit('router/setOpenedMenu', null);
          }
        }
      }
    });
  },
  methods: {
    toggle(close, wait = 400) {
      if (!this.isLargerThanMd) {
      // eslint-disable-next-line no-param-reassign
        if (close === undefined) close = this.minifiedMenu;
        if (close) {
          this.minifiedMenu = false;
          this.currentLink = null;
          this.resetMap();
          setTimeout(() => this.unlock(), wait);
        } else {
          this.minifiedMenu = true;
          setTimeout(() => this.lock(), wait);
        }
      }
    },
    activeItem() {
      let ret;
      [...this.sublinks.keys()].forEach((key) => {
        if (this.sublinks.get(key).shown) ret = key;
      });
      return ret;
    },
    resetMap() {
      this.navigation?.links.forEach((item) => {
        const ref = this.$refs[`ref${item.id}`];
        this.sublinks.set(item.id, {
          shown: false,
          id: item.id,
          el: () => ref[0],
          bounds: !!item.subs.length && (() => ref[0]?.getBoundingClientRect()),
          hasSubs: !!item.subs.length,
        });
      });
    },
    hasSubLinks(linkIdx) {
      if (this.navigation.links[linkIdx].navigable && this.navigation.links[linkIdx].navigable === 'true') {
        return true;
      }
      return !this.navigation.links[linkIdx].subs.length;
    },
    hasSubSubLinks(linkIdx, subLinkIdx) {
      return !this.navigation.links[linkIdx].subs[subLinkIdx]?.subs.length;
    },
    hoverOn(link) {
      if (this.currentLink !== link.id) {
        this.resetMap();
        this.$store.commit('router/setOpenedMenu', null);
      }
      this.$store.commit('router/setOpenedMenu', link);
      this.currentLink = link.id;
      this.sublinks.set(link.id, { ...this.sublinks.get(link.id), shown: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {

}
.logo {
  svg {
    max-width: 200px;
  }
}
.logo-full {
  svg {
    width: 50vw;
    height: auto;
    @screen md {
      width: 70vw;
      height: 1.8rem;
      width: auto;
    }

    @screen lg {
      height: 2.2rem;
    }
  }
}

nav {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.nav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  background: white;
  height: $header-height;

  > div {
    max-width: unset !important;
  }
}

.link {
  &.active {
    text-decoration: underline;
  }
}

/* MOBILE NAV */

nav.active {
  &::after {
    content: "";
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(6px);
    position: fixed;
    inset: -10vw;
    z-index: -1;
  }

  ul.primary-list {
    visibility: visible;
    height: calc(100vh - 6rem);
  }
}

ul.primary-list {
  visibility: hidden;
  margin-top: $header-height;

  &.active {
  }

  @screen md {
    margin-top: unset;
    visibility: visible !important;
  }
}

.hamburger {
}

ul.secondary-list{
  @screen md{
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

div.highlights-container{
  @screen md{
    padding-left: 3rem;
  }
}

li.li-primary-link {

  @screen md {
    div.secondary-list-container {
      visibility: hidden;
      z-index: -1;
      transform: translateY(-100%);
      transition: transform 500ms ease-out;
      padding: calc(#{$header-height} + 1rem) 1rem 2rem 1rem;

    }

      div.secondary-list-container.displayed {
        visibility: visible;
        transform: translateY(0);
      }
  }

  div.secondary-list-container {
    display: flex;
    height: fit-content;
  }
}

nav li a {
  @screen md {
    font-size: 1rem !important;
  }

  @screen lg {
    font-size: 1.1rem !important;
  }
}

.nav-top-bar {
  a, :deep(a) {
    font-size: 0.85rem !important;
  }
}

li.li-primary-link > a {
  font-weight: bold;
  display: block;
  @screen md {
    font-size: 1.13rem;
    font-weight: normal;
    padding-bottom: 0;
  }
}

li.li-secondary-link .secondary-link {
  font-size: 1rem;
}

li.li-secondary-link > a {
  font-size: 1.05rem;
  font-weight: normal;

  @screen md {
    font-size: 1.13rem;
    font-weight: bold;
  }
}

li.li-tertiary-link > a {
  font-size: 0.95rem;
  opacity: 0.9;

  @screen md {
    opacity: 1;
    font-size: 1.13rem;
  }
}

</style>
