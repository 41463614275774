<template>
  <div class="Breadcrumb part constrain mt-4 flex flex-wrap gap-2">
    <span :class="theme" class="py-1 px-2">
      <span
        v-for="item in breadcrumb"
        :key="item"
        class="breadcrumb-item"
        v-html="item"
      />
    </span>
  </div>
</template>

<script setup>

import { computed, defineProps } from 'vue';

const props = defineProps({
  payload: Object,
});

const themes = {
  blue: 'bg-sitem text-white',
  white: 'bg-white text-black',
};

const breadcrumb = computed(() => {
  const list = props.payload.string.split('::');
  if (list[0] === list[1]) list.shift();
  if (list[0].includes('></a>')) list[0] = list[0].replace('</a>', 'Homepage</a>');
  return list;
});

const theme = computed(() => themes[props.payload.color]);

</script>

<style scoped lang="scss">

div.Breadcrumb {
  margin-top: 4rem !important;
  .bg-white {
    &:first-child {
      @apply pl-0;
    }
  }
}

span.breadcrumb-item {
  &:not(:last-of-type)::after {
    content: '>';
    padding: 0 0.5rem;
  }

  :deep(a){
    &:hover{
      @apply text-pool;
    }
  }
}

</style>
